<template>
  <b-container class="mb-7">
    <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
    <b-overlay :show="loading">
      <b-card title="User Info" class="form-width">
        <b-row>
          <b-col cols="2" class="text-right"><strong>First Name</strong></b-col>
          <b-col>{{ user.firstName }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2" class="text-right"><strong>Last Name</strong></b-col>
          <b-col>{{ user.lastName }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2" class="text-right"><strong>Username</strong></b-col>
          <b-col>{{ user.username }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="2" class="text-right"><strong>Email</strong></b-col>
          <b-col v-if="!firebase">
            {{ user.email }}
          </b-col>
          <b-col v-else>
            <div>
              {{ user.email }}
              <b-badge variant="warning" v-if="user.emailVerified === false">Unverified</b-badge>
              <b-badge variant="success" v-if="user.emailVerified">Verified</b-badge>
            </div>
            <div v-if="user.emailVerified === false && !verificationSent">
              <b-overlay class="d-inline-block" :show="sendingVerification">
                <b-button variant="outline-secondary" @click="resendVerification"> Resend Verification Email </b-button>
              </b-overlay>
            </div>
            <b-alert variant="success" :show="verificationSent">Verification Sent</b-alert>
            <b-alert variant="danger" show class="d-inline-block" v-if="verificationErrorMessage">
              {{ verificationErrorMessage }}
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2" class="text-right"><strong>Roles</strong></b-col>
          <b-col>
            {{ user.roles.join(', ') }}
          </b-col>
        </b-row>
      </b-card>
      <b-card title="Reset Password" class="form-width mt-3">
        <b-form @submit.prevent="resetPassword">
          <b-form-group
            label="Current Password"
            label-for="input-current-password"
            :invalid-feedback="veeErrors.first('input-current-password')"
          >
            <b-form-input
              name="input-current-password"
              v-model="currentPassword"
              type="password"
              v-validate="{ required: true }"
              :state="validateState('input-current-password')"
              data-vv-as="current password"
            />
          </b-form-group>
          <b-form-group
            label="New Password"
            label-for="input-new-password"
            :invalid-feedback="veeErrors.first('input-new-password')"
          >
            <b-form-input
              name="input-new-password"
              v-model="newPassword"
              type="password"
              v-validate="{ password: true, required: true }"
              :state="validateState('input-new-password')"
              data-vv-as="new password"
            />
          </b-form-group>

          <b-form-group
            label="Confirm New Password"
            label-for="input-confirm-password"
            :invalid-feedback="veeErrors.first('input-confirm-password')"
          >
            <b-form-input
              autocomplete="new-password"
              name="input-confirm-password"
              v-model="confirmNewPassword"
              type="password"
              v-validate="{ confirmed: newPassword }"
              :state="validateState('input-confirm-password')"
              data-vv-as="password confirmation"
            />
          </b-form-group>
          <div class="mt-4 d-flex">
            <b-overlay :show="resettingPassword" class="d-inline-block">
              <b-button type="submit" variant="outline-secondary">Reset Password</b-button>
            </b-overlay>
            <b-alert variant="success" class="ml-3" v-model="passwordResetSuccess" dismissible fade>
              Password Reset Successfully.
            </b-alert>
            <div class="alert alert-danger ml-3" v-if="resetPasswordError">
              {{ resetPasswordError }}
            </div>
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-container>
</template>
<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import UserService from '@/lib/user-service-v2';

export default {
  data() {
    return {
      user: {
        roles: []
      },
      loading: true,
      errorMessage: '',
      verificationErrorMessage: null,
      sendingVerification: false,
      verificationSent: false,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      resetPasswordError: '',
      resettingPassword: false,
      passwordResetSuccess: 0,
      sessionUser: null
    };
  },
  async mounted() {
    this.loading = true;
    await this.loadUser();
    this.loading = false;
  },
  computed: {
    firebase() {
      return this.user.firebase;
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    async loadUser() {
      try {
        const sessionUser = await getAuth().sessionUser();
        const user = await UserService.retrieveUser(sessionUser.id);

        this.user = user;
      } catch (error) {
        this.errorMessage = `Error loading user details - ${this.parseError(error).message}`;
      }
    },

    async resendVerification() {
      this.verificationErrorMessage = '';

      try {
        this.sendingVerification = true;
        const sessionUser = await getAuth().sessionUser();
        await UserService.sendVerificationEmail(sessionUser.id);
        this.verificationSent = true;
      } catch (error) {
        this.verificationErrorMessage = `Error sending email verification - ${this.parseError(error)}`;
      }

      this.sendingVerification = false;
    },

    resetResetPasswordForm() {
      this.currentPassword = null;
      this.newPassword = null;
      this.confirmNewPassword = null;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    async resetPassword() {
      this.resetPasswordError = '';

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      this.resettingPassword = true;

      const updateData = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword
      };

      try {
        const sessionUser = await getAuth().sessionUser();
        await UserService.changePassword(sessionUser.id, updateData);

        this.resetResetPasswordForm();

        this.passwordResetSuccess = 5;
      } catch (error) {
        let message = this.parseError(error).message;
        if (message === 'unauthorized') {
          message = 'Ensure you have entered your current password correctly';
        }
        this.resetPasswordError = `Error resetting password - ${message}`;
      }
      this.resettingPassword = false;
    }
  }
};
</script>

<style scoped>
.form-width {
  max-width: 700px;
}
</style>
